import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import Navbar from './components/Common/Navbar/Navbar';
import OtherProjectsPage from './views/OtherProjects/OtherProjectsPage';
import AboutUsPage from './views/AboutUs/AboutUsPage';
import CareerPage from './views/Career/CareerPage';
import Footer from './components/Common/Footer/Footer';

import { useEffect } from 'react';
import { english } from './data/english';

function App() {
  let location = useLocation();

  // const navigate = useNavigate();

  // let { lang, setLang } = useContext(LanguageContext);
  // const [dataByLanguage, setDataByLanguage] = useState(english);

  // useEffect(() => {
  //   if (lang !== "pl" && lang !== "en") {
  //     setLang(
  //       navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0]
  //     );

  //     lang =
  //       navigator.language.split("-")[0] ||
  //       navigator.userLanguage.split("-")[0];

  //     location = location.split("/")[2] ? location.split("/")[2] : "";

  //     if (location.length > 2) {
  //       navigate(`/${lang}/${location}`);
  //     } else navigate(`/${lang}`);
  //   }
  //   const languageData = lang === "pl" ? polish : english;
  //   setDataByLanguage(languageData);

  // }, [lang, setLang, navigate, location]);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="App" id="App">
      <Navbar data={english.navbar} />
      <Routes>
        <Route exact path="*" element={<HomePage data={english.homePage} />} />
        <Route
          exact
          path="other-projects"
          element={<OtherProjectsPage data={english.otherProject} />}
        />
        <Route
          exact
          path="/about-us"
          element={<AboutUsPage data={english.about} />}
        />
        <Route
          exact
          path="/career"
          element={<CareerPage data={english.career} />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
