export const english = {
  navbar: {
    links: ['About us', 'Career', 'Contact'],
    aboutUs: ['Our Products', 'Smartado', 'Other Projects'],
  },
  homePage: {
    heroSection: {
      title: 'Top-tier language learning experience',
      subtitle: 'See more',
    },
    aboutSection: {
      tittle: 'About us',
      subtitle: `Our specialists have dedicated themselves to researching the most effective methods of academic learning and have carefully selected empirically verified, well-established approaches to language teaching. They then skillfully tailored comprehensive educational resources for the digital world, culminating in a robust curriculum available at your convenience, regardless of your location or preferred learning schedule.`,
      catchWordsTitle: 'From our perspective:',
      catchWords: `Everyone deserves access to quality education and continuous lifelong learning opportunities.
      `,
    },
    offerSection: {
      title: 'Why education matters to our society?',
      paragrafOne: 'More Employment Opportunities ',
      paragrafTwo: 'Higher Income',
      paragrafThree: 'Problem-solving Skills',
      paragrafFour: 'Prosperous and Happy Life',
      text: `
      Learning foreign languages promotes more positive attitudes and reduces prejudice against people from different backgrounds.`,
    },
    contactSection: {
      title: 'Contact us',
      name: 'Name',
      lastName: 'Last name',
      email: 'e-mail adress',
      message: 'Message content',
      submit: 'Send',
    },
  },
  about: {
    hero: { headline: 'About us' },
    about: {
      textUp: `
      Our strategy? Cultural value serves as an intangible catalyst to propel individuals, brands and societies toward progress. It stems from what matters most to people and is cultivated through the actions of organizations around the world. Influential concepts ignite, earn and engage individuals around the world.`,
      textDown: `It is born from what matters most to people, and earned by how an organization shows up in the world. Effective ideas inspire, earn and activate people around the globe.

        `,
    },
    company: {
      title: 'AT DreamAi WE VALUE',
      subtitle: `Competence`,
      text: `From the very beginning, we entrust everyone with responsibility. We consistently fulfill our commitments and ensure mutual respect`,
      subtitle1: ` Advancements & Novelty`,
      paragraphOne: `As we strive to be recognized as a technology leader in our industry, we are dedicated to prudent investment in the right products, systems and equipment, striving for excellence.`,
      paragraphTwo: `Our goal is to instill a culture of creativity, innovation and inspiration in every employee by creating a climate that encourages thoughtful risk-taking.`,
      subtitle2: `Expansion & Financial Success`,
      paragraphThree: `Our goal is to ensure sustainable, balanced growth for our company. We take a balanced and prudent approach to investment and spending to drive our expansion.`,
      paragraphFour: `We recognize that continued profitability is critical to the longevity of our organization.`,
    },
  },
  career: {
    hero: {
      headline: 'Career',
      hash: '#open-positions',
      text: 'See open positions',
    },
    description: {
      text: `At DreamAi, we simplify language learning by integrating top-notch courses with instant feedback from our community around the world. If you are passionate about languages, eager to work with smart, innovative and enthusiastic people, and equipped with the initiative, confidence and discernment to make independent decisions every day, you will thrive in our dynamic environment!
      `,
    },

    company: {
      title: 'AT DreamAi WE VALUE',
      data: [
        {
          headline: 'Trust',
          text: `From day one, we entrust everyone with responsibility. We consistently deliver on our promises and nurture mutual respect.`,
        },
        {
          headline: 'Effectiveness',
          text: `For us, empowering our customers means solving the right problems –
                then going above and beyond to get stuff done.`,
        },
        {
          headline: 'Ambition',
          text: `We always think big and try to go the extra mile – and we pride ourselves
                on leaving a legacy.`,
        },
        {
          headline: 'Curiosity',
          text: `We like to question the status quo. We test, learn and share so we
                can better ourselves and our work every day.`,
        },
      ],
    },
    job: {
      // title: "OPEN POSITIONS",
      // paragraphOneTitle: "Call centre operator with Romanian",
      // paragraphOneLiOne: "Place of work: remote",
      // paragraphOneLiTwo: "Type of employment: full-time, part-time",
      // paragraphTwoTitle: "Job description",
      // paragraphTwoLiOne:
      //   "Telephone sales of the company's products to our customers      ",
      // paragraphTwoLiTwo: "Confirming customer orders and increasing sales",
      // paragraphTwoLiThree:
      //   "Maintaining positive relationships with customers and ensuring their satisfaction",
      // paragraphThreeTitle: "Required",
      // paragraphThreeLiOne: "Interpersonal communication at a high level      ",
      // paragraphThreeLiTwo:
      //   "Enthusiasm and energy in discussions with customers      ",
      // paragraphThreeLiThree:
      //   "Enthusiasm and energy in discussions with customers      ",
      // paragraphFourTitle: "Required:",
      // paragraphFourLiOne: "Interpersonal communication at a high level",
      // paragraphFourLiTwo: "Enthusiasm and energy in discussions with customers",
      // paragraphFourLiThree: "English at a communicative level (B1)",
      // paragraphFiveTitle: "We offer:",
      // paragraphFiveLiOne:
      //   "Base salary for every hour worked + high sales commissions (chance to double your salary)        ",
      // paragraphFiveLiTwo: "Comfortable working from home",
      // paragraphFiveLiThree:
      //   "Flexible working hours (minimum 20 hours per week from 9am to 8pm)",
      // paragraphFiveLiFour:
      //   "Opportunity for rapid development within our organisation - promotion to Team Leader",
      // paragraphSixTitleSix: "Language skills",
      // paragraphSixLiOne:
      //   "Hungarian/Romanian/Croatian/Lithuanian/Slovak - fluent (C2)",
      // paragraphSIxLiTwo: "English (B1)",
      // textUp: `Get in touch! We’re always delighted to say hello to a fresh face.`,
      // textDown: `We reserve the right to contact only selected individuals.`,
    },
    section: {
      title: 'Contact us',
      name: 'Name',
      lastName: 'Last name',
      email: 'e-mail adress',
      message: 'Message content',
      submit: 'Send',
    },
  },
};
